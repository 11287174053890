


























































import { Component, Vue } from 'vue-property-decorator'
import Col from '../../shared/src/components/Col.vue'
import Row from '../../shared/src/components/Row.vue'
import Btn from '../../shared/src/components/Btn.vue'
import ContentRow from '../components/ContentRow.vue'
import Sizzle from '../components/Sizzle.vue'
import SvgMeasureLines from '../components/SvgMeasureLines.vue'
import CourtColorPicker from '../components/CourtColorPicker.vue'
import PickleballViewer from '../components/PickleballViewer.vue'
import TennisViewer from '../components/TennisViewer.vue'
import CourtColors from '../helpers/CourtColors'

@Component({
  components: {
    Sizzle,
    Col,
    Row,
    Btn,
    SvgMeasureLines,
    ContentRow,
    CourtColorPicker,
    PickleballViewer,
    TennisViewer
  }
})
export default class ColorPicker extends Vue {
  pickleballAngle = 'flat'
  pickleball = {
    border: CourtColors[0],
    zone1: CourtColors[0],
    zone2: CourtColors[0],
    zone3: CourtColors[0],
    zone4: CourtColors[0],
    kitchen: CourtColors[0]
  }
  tennisAngle = 'flat'
  tennis = {
    border: CourtColors[0],
    alley1: CourtColors[0],
    bay1: CourtColors[0],
    alley2: CourtColors[0],
    rightServiceCourt1: CourtColors[0],
    leftServiceCourt1: CourtColors[0],
    alley3: CourtColors[0],
    bay2: CourtColors[0],
    alley4: CourtColors[0],
    rightServiceCourt2: CourtColors[0],
    leftServiceCourt2: CourtColors[0],
  }
  colors = CourtColors
}
