























import { Vue, Component, Prop } from 'vue-property-decorator'
import SvgMeasureLines from './SvgMeasureLines.vue'
import CourtColors, { CourtColor } from '../helpers/CourtColors'

const angled = `transform: perspective(15em) translate3d(0, -20%, 40px)  rotate3d(1, 0, 0, 40deg)`
const flat = `transform: translateY(-10%) perspective(0em) rotateX(0deg)`
const lineStyle = `fill:none;stroke:#666;stroke-width:0.2px;`
const textStyle = `fill:#666;stroke:none;`

interface TennisViewerData {
  border: CourtColor
  alley1: CourtColor
  bay1: CourtColor
  alley2: CourtColor
  rightServiceCourt1: CourtColor
  leftServiceCourt1: CourtColor
  alley3: CourtColor
  bay2: CourtColor
  alley4: CourtColor
  leftServiceCourt2: CourtColor
  rightServiceCourt2: CourtColor
}

@Component({
  components: {
    SvgMeasureLines
  }
})
export default class TennisCourtViewer extends Vue {
  @Prop({ default: 188 }) width!: number
  @Prop({ default: 300 }) height!: number
  @Prop({ default: 'angled' }) angle!: 'flat' | 'angled'
  @Prop({
    default: () => {
      return {
        border: CourtColors[0],
        alley1: CourtColors[0],
        bay1: CourtColors[0],
        alley2: CourtColors[0],
        rightServiceCourt1: CourtColors[0],
        leftServiceCourt1: CourtColors[0],
        alley3: CourtColors[0],
        bay2: CourtColors[0],
        alley4: CourtColors[0],
        leftServiceCourt2: CourtColors[0],
        rightServiceCourt2: CourtColors[0]
      }
    }
  })
  zones!: TennisViewerData

  lineStyle = lineStyle
  textStyle = textStyle
  angled = angled
  flat = flat
  trans = { x: 14, y: 14, width: 60, height: 120 }
  lines = [
    {
      start: { x: -6, y: 0 },
      end: { x: -6, y: 120 }
    },
    {
      start: { x: 12, y: 123 },
      end: { x: 48, y: 123 }
    },
    {
      start: { x: 0, y: 127 },
      end: { x: 60, y: 127 }
    },
  ]

  get displayAngle(): string {
    return this.angle === 'angled' ? angled : flat
  }
}
