















import { Vue, Component, Prop } from 'vue-property-decorator'
import { Hash } from '../../shared/src/types/Hash'
import Col from '../../shared/src/components/Col.vue'
import Row from '../../shared/src/components/Row.vue'
import Btn from '../../shared/src/components/Btn.vue'
import ContentRow from '../components/ContentRow.vue'

interface VItem {
  color: string
  label: string
}

@Component({
  components: {
    Row,
    Col,
    Btn,
    ContentRow
  }
})
export default class CourtColorPicker extends Vue {
  @Prop({ default: '' }) colors!: VItem[]
  @Prop({ default: '' }) selected!: Hash<VItem>
}
