

















import { Vue, Component, Prop } from 'vue-property-decorator'
import SvgMeasureLines from './SvgMeasureLines.vue'
import CourtColors, { CourtColor } from '../helpers/CourtColors'

const angled = `transform: perspective(15em) translate3d(0, -20%, 40px)  rotate3d(1, 0, 0, 40deg)`
const flat = `transform: translateY(-10%) perspective(0em) rotateX(0deg)`
const lineStyle = `fill:none;stroke:#666;stroke-width:0.2px;`
const textStyle = `fill:#666;stroke:none;`

interface PickleballViewerData {
  border: CourtColor
  zone1: CourtColor
  zone2: CourtColor
  zone3: CourtColor
  zone4: CourtColor
  kitchen: CourtColor
}

@Component({
  components: {
    SvgMeasureLines
  }
})
export default class PickleballCourtViewer extends Vue {
  @Prop({ default: 188 }) width!: number
  @Prop({ default: 300 }) height!: number
  @Prop({ default: 'angled' }) angle!: 'flat' | 'angled'
  @Prop({
    default: () => {
      return {
        border: CourtColors[0],
        zone1: CourtColors[0],
        zone2: CourtColors[0],
        zone3: CourtColors[0],
        zone4: CourtColors[0],
        kitchen: CourtColors[0]
      }
    }
  })
  zones!: PickleballViewerData

  lineStyle = lineStyle
  textStyle = textStyle
  angled = angled
  flat = flat
  trans = { x: 10, y: 10, width: 30, height: 60 }
  lines = [
    {
      start: { x: -6, y: 0 },
      end: { x: -6, y: 60 }
    },
    {
      start: { x: -2, y: 8 },
      end: { x: -2, y: 52 }
    },
    {
      start: { x: 5, y: 62 },
      end: { x: 25, y: 62 }
    },
    {
      start: { x: 0, y: 66 },
      end: { x: 30, y: 66 }
    },
    {
      start: { x: 32, y: 30 },
      end: { x: 32, y: 37 }
    },
    {
      start: { x: 32, y: 37 },
      end: { x: 32, y: 52 }
    },
    {
      label: '36" Net',
      start: { x: 35, y: 30 }
    },
    {
      label: 'Base Line',
      start: { x: 22, y: 50 }
    },
    {
      label: 'Kitchen',
      start: { x: 16.5, y: 27 }
    },
    {
      label: 'Kitchen',
      start: { x: 16.5, y: 34 }
    },
    {
      label: 'Side Line',
      start: { x: -20, y: 3, r: -90 }
    }
  ]

  get displayAngle(): string {
    return this.angle === 'angled' ? angled : flat
  }
}
